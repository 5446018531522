<!-- @Author: Yu_Bo -->
<template>
    <div class='account_manage'>
        <!-- 标题 -->
        <div class="vue_title">账号管理</div>
        <div class="account_main">
            <!--  -->
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addEditBtn(1,'')" size="small" icon="el-icon-plus">新增</el-button>
                    <el-select v-model="roleValue" size="small" @change="search" :clearable="true" placeholder="请选择角色">
                        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-input v-model="mobile" size="small" @keyup.enter.native="search" @clear='search' :clearable="true" placeholder="搜索账号"></el-input>
                </div>
                <div class="cond_right">
                    <el-button type="primary" class="btnBgColor_grey" size="small" icon="el-icon-upload2" @click='exportBtn'>导出</el-button>
                </div>
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" ref="multipleTable" @selection-change="handleSelectionChange" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <!-- <el-table-column type="selection" align="center" width="55"></el-table-column> -->
                    <el-table-column prop="mobile" label="账号"></el-table-column>
                    <el-table-column prop="nickname" label="姓名/昵称"></el-table-column>
                    <el-table-column prop="role.name" label="角色"></el-table-column>
                    <el-table-column label="创建人">
                        <template slot-scope="scope">
                            <span>{{scope.row.create_user_mobile || '--'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间"></el-table-column>
                    <el-table-column prop="update_time" label="更新时间"></el-table-column>
                    <el-table-column prop="" label="最后登录">
                        <template slot-scope="scope">
                            <span>{{scope.row.last_time || '--'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="switch" label="是否启用">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="addEditBtn(2,scope.row)">编辑</el-button>
                            <el-button type="text" size="small" @click="delBtn(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="accountDel" @close='closeListBtn' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">删除该账号后不能再登录，确定要删除该账号吗？</div>
            </div>
        </point-out>
        <!-- 新增/编辑 -->
        <account-add-edit ref="dialogAccount" :type="account_type" :roleList="options" @submit='submitBtn'></account-add-edit>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import AccountAddEdit from '../components/account_add_edit/account_add_edit.vue'//新增/编辑
export default {
    components: { PointOut, EmptyData, PagingPage, AccountAddEdit },
    props: {},
    data() {
        return {
            roleValue: '',
            options: [],
            mobile: '',
            //
            list: [],
            loading: false,
            ids: [],
            multipleSelection: [],
            //
            page: 1,
            limit: 10,
            total: 0,
            // 删除
            del_index: '',
            // 新增/编辑
            account_type: 1,
        }
    },
    computed: {},
    watch: {},
    created() {
        this.paramsInfo()
        this.search()
    },
    mounted() { },
    methods: {
        paramsInfo() {
            var that = this
            that.$purviewApi.systemUserParams().then(res => {
                if (res.code == 1000) {
                    that.options = res.result.role
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        search() {
            this.page = 1
            this.list = []
            this.total = 0
            this.getList()
        },
        // 列表
        getList() {
            var that = this
            that.loading = true
            var params = {
                role_id: that.roleValue,
                mobile: that.mobile,
                page: that.page,
                limit: that.limit,
            }
            that.$purviewApi.systemUser(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.list = res.result?.data
                    that.total = res.result?.total
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 表单选择
        handleSelectionChange(val) {
            this.ids = []
            val.forEach(item => {
                this.ids.push(item.id)
            })
            this.multipleSelection = val
        },
        // 修改状态
        changeSwitch(val, info, index) {
            var that = this
            var params = {
                id: info.id,
                status: val,
            }
            that.$purviewApi.systemUserStatus(params).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.list[index].status = val
                } else {
                    that.list[index].status = val == 1 ? 2 : 1
                    that.$errMsg(res.message)
                }
            })
        },
        // 新增/编辑
        addEditBtn(type, info) {
            this.account_type = type
            this.$refs.dialogAccount.openDialogBtn(info)
        },
        // 删除
        delBtn(index) {
            this.del_index = index
            this.$refs.dialogTip.openDialogBtn()
        },
        closeListBtn() {
            var that = this
            if (that.del_index !== '') {
                that.$purviewApi.deleteSystemUser('', that.list[that.del_index].id).then(res => {
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.getList()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        // 新增/编辑=确定
        submitBtn() {
            this.getList()
        },
        // 导出
        exportBtn() {
            var that = this
            var params = {
                role_id: that.roleValue,
                mobile: that.mobile,
                ids: that.ids.join(','),
            }
            that.$purviewApi.userExport(params).then(res => {
                return
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.account_manage {
    width: 100%;
    .account_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
            .cond_right {
                .el-button {
                    padding: 8px 10px;
                }
            }
        }
    }
}
</style>
